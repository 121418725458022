.sider-menu {
  font-family: @font-family;
  width: 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  min-height: 960px !important;
  background: white !important;
  border-right: @border-color-base !important;
  padding: 0px 0px;
  border-right: 1px solid rgb(221, 226, 237) !important;
  .container-logo {
    height: 130px;
    padding: 48px 0px 0px 36px;
    > .bg-isoogo {
      width: 48px;
      height: 48px;
      border: 2px solid @primary;
      border-radius: 8px;
      padding: 6px;
      img {
        width: 32px;
        height: 32px;
      }
    }
    > h2 {
      margin-top: 12px;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
    }
  }

  .menu {
    margin-top: 40px;
    width: 249px;
    height: 497px;
    border-right: 0px;
    border-radius: 0px;
    > li {
      ::after {
        border-right: 3px solid @primary !important;
      }
    }
    > li.ant-menu-item-selected,
    li.ant-menu-item-selected a {
      color: @primary !important;
      width: 249px;
      background-color: transparent !important;
    }
    > li.ant-menu-item-selected::after {
      border-right: 3px solid @primary !important;
    }

    > li.ant-menu-item-disabled {
      color: @primary-color-outline !important;
      background-color: transparent !important;
    }
  }
  .ant-menu-item-selected {
    background-color: @primary-color-active;
  }

  .menu-item {
    display: flex;
    width: 224px;
    height: 40px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 40px;
    color: @primary;
    border-radius: 0px;
    > li {
      line-height: 40px;
      margin: 9px 0;
      span {
        width: 24px;
        height: 24px;
        > img {
          color: @primary !important;
        }
      }
    }
    > span {
      padding: 0 0 0 16px;
      a {
        :hover {
          border-radius: 0px;
          color: @primary !important;
        }
      }
      svg {
        margin-right: 9px;
      }
    }
    :hover {
      background-color: transparent !important;
    }
  }
}

@black: #2A2A33;@grayscalePlaceholders: #77787E;@grayscaleBorders: #DDE2ED;@grayscaleBackgrounds: #EFF2F9;@grayFocused: #C3C5CC;@greenMain: #40CD87;@greenLight: #F3F9F6;@redMain: #ED6868;@redLight: #FAF2F1;@yellowDark: #EBC40E;@yellowLight: #F7F5ED;@veryLightGray: #fafafa;@lightGrayishBlue: #e2e3ea;@blueMain: #D9E6E1;@davy-grey: #555555;@very-light-gray: #e3e3e3;@font-family: Roboto;@primary: #0f1c30;@primary-1: #0f1c3020;@primary-color-hover: #0f1c3060;@primary-color-active: #0f1c3060;@primary-color-outline: #0f1c3060;@primary-lightest: lighten(@primary, 30%);@primary-lighter: lighten(@primary, 10%);@primary-dark: darken(@primary, 8%);@primary-darkest: darken(@primary, 10%);@secondary: rgb(79, 93, 117);@secondary-lightest: lighten(@secondary, 60%);@secondary-lighter: lighten(@secondary, 35%);@secondary-darker: darken(@secondary, 8%);@secondary-darkest: darken(@secondary, 10%);@text-color: @primary;@text-color-disabled: @secondary-lighter;@button-color: @secondary-lightest;@button-color-disabled: @secondary-lighter;@layout-header-background: #0f1c30;@primary-color: @primary;@processing-color: @primary-color;@normal-color: #d9d9d9;@white: #fff;@link-color: @primary;@text-color-secondary: @secondary;@border-radius-base: 4px;@border-color-base: #DDE2ED;@secondary-hover-color: #7283a0;@mentions-dropdown-menu-item-hover-bg: rgb(114, 131, 160);@menu-item-active-bg: #95a1b850;@input-color: @text-color;@input-hover-border-color: #e2e3ea;@box-shadow-base: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;